<template>
  <div>
    <NuxtLoadingIndicator :throttle="0" />
    <div class="sticky top-0 border-b-2 z-10 bg-white dark:bg-gray-900">
      <Menubar
        :model="items"
        class="mx-auto container border-0 z-10 bg-transparent dark:bg-transparent"
      >
        <template #item="data">
          <router-link
            :to="data.item.target"
            class="text-gray-500 dark:text-white mx-3 active:text-white transition-colors"
          >
            <Icon
              :name="data.item.icon"
              size="1.5rem"
              class="text-gray-500 active:text-white transition-colors"
            />
            {{ data.item.label }}
          </router-link>
        </template>
        <template #end>
          <router-link
            to="/"
            class="text-white"
            aria-label="Go home"
          >
            <Icon
              size="2.5rem"
              name="bi:house-heart"
              class="text-gray-500 dark:text-white active:scale-[0.75] transition-transform"
            />
          </router-link>
        </template>
      </Menubar>
    </div>
    <div class="container mx-auto px-3" style="max-width: 768px">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">

import type { MenuItem } from 'primevue/menuitem'

const items = ref([
  {
    label: 'ប្លុក',
    icon: 'bxl:blogger',
    target: '/blogs'
  }

] satisfies MenuItem[])

</script>
